'use client'

import { usePathname, useParams } from 'next/navigation'
import React, { createContext, useEffect, useState } from 'react'
import { processFilterPatternsForClient } from 'src/@core/search/filters/filters.patterns'
import search, {
    Filter,
    INITIAL_STATE,
    Region,
    SearchState,
    SerializedSearchState,
    Sort,
    buildUrl,
    buildUrlV2,
} from 'src/@core/search/search.entity'
import { BairroLogradouroType } from 'src/@core/search/search.model'

type SearchContextProps = {
    state: SearchState
    page?: number
    setRegions?: (regions: Region[]) => void
    setLogradouro?: (l?: BairroLogradouroType) => void
    setPage?: (page: number) => void
    setSort?: (sort: Sort) => void
    addFilters?: (filters: Filter[]) => void
    addSingleFilter?: (filter: Filter) => void
    removeFilter?: (filter: Filter) => void
    clearFilters?: () => void
}

interface SearchProviderProps {
    children: React.ReactNode
    initialData?: SerializedSearchState
}

export const SearchContext = createContext<SearchContextProps>({
    state: INITIAL_STATE,
})

export const ROWS_PER_PAGE = 48

export const SearchProvider = ({ children, initialData }: SearchProviderProps) => {
    const pathname = usePathname()
    const params = useParams()

    const clientFilterPatterns = processFilterPatternsForClient(
        initialData?.serializedFilterPatterns
    )
    const clientInitialData = {
        ...initialData,
        serializedFilterPatterns: clientFilterPatterns,
    } as SearchState
    let [state, setState] = useState<SearchState>(clientInitialData ?? INITIAL_STATE)

    const setRegions = (regions: Region[]) =>
        setState((prevState: SearchState) => search.setRegions(regions, prevState))

    const setLogradouro = (l?: BairroLogradouroType) =>
        setState((prevState: SearchState) => search.setLogradouro(l, prevState))

    const setSort = (sort: Sort) => setState((prevState) => search.setSort(sort, prevState))

    const addFilters = (filters: Filter[]) =>
        setState((prevState) => search.addFilters(filters, prevState))

    const removeFilter = (filter: Filter) =>
        setState((prevState) => search.removeFilter(filter, prevState))

    const addSingleFilter = (filter: Filter) =>
        setState((prevState) => search.addSingleFilter(filter, prevState))

    const clearFilters = () => setState((prevState) => search.clearFilters(prevState))

    useEffect(() => {
        // the timeout adds a small delay to reduce the errors logged by Next when a navigation is interrupted
        const timeout = setTimeout(() => {
            if (pathname?.includes('/imoveis/aluguel/')) {
                window.history.pushState({}, '', buildUrl(state))
            }
            if (pathname?.includes('/alugar/imovel/') || pathname?.includes('/comprar/imovel')) {
                window.history.pushState({}, '', buildUrlV2(state, params))
            }
        }, 100)

        return () => {
            clearTimeout(timeout)
        }
    }, [state])

    return (
        <SearchContext.Provider
            value={{
                state,
                setRegions,
                setLogradouro,
                setSort,
                addFilters,
                removeFilter,
                clearFilters,
                addSingleFilter,
            }}
        >
            {children}
        </SearchContext.Provider>
    )
}
