import slugify from 'slugify'
import { fetchFilterValues } from './filters.gateway'
import { Filter } from '../search.entity'

export type FilterPattern = {
    pattern: RegExp
    name: string
    value: string
    suffix?: string
    priority?: number
}

export type SerializedFilterPattern = {
    pattern: string
    name: string
    value: string
    suffix?: string
    priority?: number
}

const getFilterValues = async () => {
    const antesEraValues = await fetchFilterValues({
        detail_type_id: '4ac4190b-9161-4ef7-b9e4-ff327e9c3196',
        detail_type_value_type: 'string',
        only_from_published_supplies: true,
    })
    const tipoDeImovelValues = await fetchFilterValues({
        detail_type_id: '01836107-2f23-7c9d-92a6-d3ff2365dbfe',
        detail_type_value_type: 'string',
        only_from_published_supplies: true,
    })

    const antesEra =
        antesEraValues?.data?.map((value) => {
            const slug = slugify(value, { lower: true, remove: /[*+~.()'"!:@/]/g })
            return {
                pattern: new RegExp(`para-${slug}`),
                name: 'antes-era',
                value,
            }
        }) || []

    const tipoDeImovel =
        tipoDeImovelValues?.data?.map((value) => {
            const slug = slugify(value, { lower: true, remove: /[*+~.()'"!:@/]/g })
            return {
                pattern: new RegExp(slug),
                name: 'tipo-de-imovel',
                value,
            }
        }) || []

    return { antesEra, tipoDeImovel }
}

export const getDynamicFilterPatterns = async (): Promise<FilterPattern[]> => {
    const { antesEra, tipoDeImovel } = await getFilterValues()

    return [
        ...antesEra,
        ...tipoDeImovel,
        { pattern: /alto-fluxo-pedestres/, name: 'fluxo-pedestre', value: '4_5' },
        { pattern: /medio-fluxo-pedestres/, name: 'fluxo-pedestre', value: '3_3' },
        { pattern: /baixo-fluxo-pedestres/, name: 'fluxo-pedestre', value: '1_2' },
        { pattern: /alto-fluxo-veiculos/, name: 'fluxo-veículo', value: '4_5' },
        { pattern: /medio-fluxo-veiculos/, name: 'fluxo-veículo', value: '3_3' },
        { pattern: /baixo-fluxo-veiculos/, name: 'fluxo-veículo', value: '1_2' },
        { pattern: /alta-densidade-populacional/, name: 'densidade', value: '4_5' },
        { pattern: /media-densidade-populacional/, name: 'densidade', value: '3_3' },
        { pattern: /baixa-densidade-populacional/, name: 'densidade', value: '1_2' },
        { pattern: /esquina/, name: 'Esquina', value: 'Sim' },
        { pattern: /nao-esquina/, name: 'Esquina', value: 'Não' },
        { pattern: /1-banheiro/, name: 'banheiros', value: '1_' },
        { pattern: /2-banheiros/, name: 'banheiros', value: '2_' },
        { pattern: /3-banheiros/, name: 'banheiros', value: '3_' },
        { pattern: /1-vagas/, name: 'vagas', value: '1_' },
        { pattern: /3-vagas/, name: 'vagas', value: '3_' },
        { pattern: /6-vagas/, name: 'vagas', value: '6_' },
        {
            pattern: /(?:de-(\d+)-a-(\d+)-reais|ate-(\d+)-reais|a-partir-de-(\d+)-reais)/,
            name: 'preço',
            value: 'any',
            suffix: 'reais',
        },
        {
            pattern: /(?:de-(\d+)-a-(\d+)-m2|ate-(\d+)-m2|a-partir-de-(\d+)-m2)/,
            name: 'tamanho-do-imovel',
            value: 'any',
            suffix: 'm2',
        },
    ]
}

const filterPriorities: Record<string, number> = {
    'tipo-de-imovel': 10,
    'antes-era': 9,
    preço: 8,
    'tamanho-do-imovel': 7,
    'fluxo-pedestre': 6,
    'fluxo-veículo': 5,
    densidade: 4,
    Esquina: 3,
    banheiros: 2,
    vagas: 1,
}

export const sortPatterns = [
    { pattern: /barato/, name: 'Menor preço', value: 'preço', sort: 'asc' },
    { pattern: /caro/, name: 'Maior preço', value: 'preço', sort: 'desc' },
    { pattern: /pequeno/, name: 'Menor tamanho', value: 'tamanho-do-imovel', sort: 'asc' },
    { pattern: /grande/, name: 'Maior tamanho', value: 'tamanho-do-imovel', sort: 'desc' },
    { pattern: /recente/, name: 'Mais recentes', value: 'published_at', sort: 'desc' },
    { pattern: /visto/, name: 'Mais vistos', value: 'pageviews', sort: 'desc' },
    { pattern: /relevante/, name: 'Mais relevantes', value: 'relevance-score', sort: 'desc' },
]

export const serializeFilterPatterns = (patterns: FilterPattern[]) => {
    return patterns.map((patternObj) => ({
        ...patternObj,
        pattern: patternObj.pattern.toString(),
    }))
}

export const deserializeFilterPatterns = (patterns: SerializedFilterPattern[]) => {
    return patterns.map((patternObj) => ({
        ...patternObj,
        pattern: new RegExp(patternObj.pattern.slice(1, -1)),
    }))
}

export const processFilterPatternsForClient = (
    filterPatterns: SerializedFilterPattern[] | undefined
): Record<string, Record<string, FilterPattern>> => {
    if (!filterPatterns) return {}
    const deserializedFilterPatterns = deserializeFilterPatterns(filterPatterns)
    const patternLookup = {} as Record<string, Record<string, FilterPattern>>
    deserializedFilterPatterns.forEach((pattern) => {
        if (!patternLookup[pattern.name]) {
            patternLookup[pattern.name] = {}
        }
        patternLookup[pattern.name][pattern.value] = pattern
    })
    return patternLookup
}

export const sortFiltersByPriority = (filters: Filter[]) => {
    const sortedFilters = filters.sort((a, b) => {
        return filterPriorities[b.name] - filterPriorities[a.name]
    })
    return sortedFilters
}
