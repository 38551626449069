import {
    FilterPattern,
    SerializedFilterPattern,
    getDynamicFilterPatterns,
    sortFiltersByPriority,
    sortPatterns,
} from './filters/filters.patterns'
import { BairroLogradouroType } from './search.model'

export const INITIAL_STATE: SearchState = {
    filters: [],
}

export type SearchState = {
    action?: string
    filters?: Filter[]
    regions?: Region[]
    logradouro?: BairroLogradouroType
    sort?: Sort
    page?: number
    serializedFilterPatterns?: Record<string, Record<string, FilterPattern>>
}

export type SerializedSearchState = {
    action?: string
    filters?: Filter[]
    regions?: Region[]
    logradouro?: BairroLogradouroType
    sort?: Sort
    page?: number
    serializedFilterPatterns?: SerializedFilterPattern[]
}

export type Region = {
    bairro: string
    count?: number
    bairro_slug: string
    center_lat?: number
    center_lng?: number
}

export type Sort = {
    name: string
    value: string
    sort: string | 'asc' | 'desc'
}

export const SortTypes: Sort[] = [
    {
        name: 'Mais relevantes',
        value: 'relevance-score',
        sort: 'desc',
    },
    {
        name: 'Mais vistos',
        value: 'pageviews',
        sort: 'desc',
    },
    {
        name: 'Mais recentes',
        value: 'published_at',
        sort: 'desc',
    },
    {
        name: 'Menor tamanho',
        value: 'tamanho-do-imovel',
        sort: 'asc',
    },
    {
        name: 'Maior tamanho',
        value: 'tamanho-do-imovel',
        sort: 'desc',
    },
    {
        name: 'Menor preço',
        value: 'preço',
        sort: 'asc',
    },
    {
        name: 'Maior preço',
        value: 'preço',
        sort: 'desc',
    },
]

export type Filter = {
    name: string
    value: string
    is_null?: boolean
    operator?: string
    type: 'int' | 'decimal' | 'string' | 'boolean'
}

type FiltersRegistryType = {
    [key: string]: 'int' | 'decimal' | 'string' | 'boolean'
}

export const FiltersRegistry: FiltersRegistryType = {
    andares: 'int',
    banheiros: 'int',
    condicao: 'int',
    densidade: 'int',
    Esquina: 'string',
    'fluxo-pedestre': 'int',
    'fluxo-veículo': 'int',
    'antes-era': 'string',
    preço: 'decimal',
    quartos: 'int',
    'tamanho-do-imovel': 'decimal',
    'tamanho-da-fachada': 'decimal',
    'tipo-de-imovel': 'string',
    vagas: 'int',
    page: 'int',
}

export const setRegions = (regions: Region[], state: SearchState) => {
    return { ...state, regions }
}

export const setLogradouro = (logradouro: BairroLogradouroType | undefined, state: SearchState) => {
    return { ...state, logradouro }
}

export const setSort = (sort: Sort, state: SearchState) => {
    return { ...state, sort }
}

export const addFilters = (filters: Filter[], state: SearchState) => {
    return { ...state, filters: [...(state.filters || []), ...filters] }
}

export const addSingleFilter = (filter: Filter, state: SearchState) => {
    if (state.filters!.filter((f) => f.name === filter.name)[0]?.value === filter?.value) {
        return state
    }

    const newState = removeFilter(filter, state)
    return addFilters([filter], newState)
}

export const removeFilter = (filter: Filter, state: SearchState) => {
    return { ...state, filters: state.filters!.filter((f) => f.name !== filter.name) }
}

export const clearFilters = (state: SearchState) => {
    return { ...state, filters: [] }
}

export const buildUrl = (state: SearchState) => {
    const queryParams = new URLSearchParams(window.location.search)

    // Remove page param, is set by pagination component
    queryParams.delete('page')

    Object.keys(FiltersRegistry).forEach((filterName) => {
        const f = state.filters?.filter((f) => f.name === filterName)[0]
        if (!f) {
            if (queryParams.has(filterName)) {
                queryParams.delete(filterName)
            }
            return
        }

        queryParams.set(f.name, f.value)
    })

    let regionUrl = 'all'
    let streetUrl = ''
    queryParams.delete('q')
    if (state.regions && state.regions.length > 0) {
        regionUrl = state.regions.map((r) => r.bairro_slug).join('_')
    }

    if (
        state?.logradouro &&
        state.logradouro.logradouro &&
        Object.keys(state.logradouro!).length > 0
    ) {
        streetUrl = state.logradouro.logradouro_slug
    }

    if (state.sort) {
        queryParams.set('sort', `${state.sort.name}_${state.sort.sort}`)
    }

    let returnPath = `/imoveis/aluguel`

    if (streetUrl !== '') {
        if (regionUrl === 'all') {
            regionUrl = state.logradouro?.bairro_slug || 'all'
        }
        returnPath = `${returnPath}/${regionUrl}/${streetUrl}`
    } else {
        returnPath = `${returnPath}/${regionUrl}`
    }
    if (queryParams.toString() !== '') {
        returnPath = `${returnPath}?${queryParams.toString()}`
    }
    return returnPath
}

export const buildFiltersFromQuery = (query: any) => {
    const ALLOWED_KEYS = Object.keys(FiltersRegistry)
    return Object.keys(query)
        .filter((key) => ALLOWED_KEYS.includes(key))
        .map((key) => ({
            name: key,
            value: query[key],
            type: FiltersRegistry[key],
            is_null: false,
            operator: FiltersRegistry[key] === 'string' ? 'in' : '',
        }))
}

export const buildUrlV2 = (state: SearchState, params: any) => {
    const filterPatterns = state.serializedFilterPatterns

    const buildBaseUrl = (action: string, slugs: string[]) => {
        let baseUrl = `/${action}/imovel`
        if (slugs.length > 0) baseUrl += `/${slugs[0]}`
        if (slugs.length > 1) baseUrl += `/${slugs[1]}`
        return baseUrl
    }

    const buildRegionUrl = (regions: Region[], logradouro: any) => {
        let regionUrl = ''
        if (regions && regions.length > 0) {
            regionUrl = regions.map((r) => r.bairro_slug).join('_')
        }
        if (logradouro?.logradouro) {
            const streetUrl = logradouro.logradouro_slug
            if (!regionUrl) {
                regionUrl = logradouro.bairro_slug
            }
            return { regionUrl, streetUrl }
        }
        return { regionUrl, streetUrl: '' }
    }

    const buildFiltersUrl = (filters: Filter[]) => {
        if (!filterPatterns || filters.length === 0) return ''
        const sortedFilters = sortFiltersByPriority(filters)

        let filtersUrl = ''
        sortedFilters?.forEach((filter) => {
            const patterns = filterPatterns[filter.name]
            if (!patterns) return

            if (filter.type === 'string') {
                const filterValues = filter.value.split(', ') as string[]
                filterValues.forEach((filterValue) => {
                    const pattern = patterns[filterValue]
                    if (pattern) {
                        filtersUrl += pattern.pattern
                    }
                })
            } else {
                const pattern = patterns[filter.value]
                if (pattern) {
                    filtersUrl += pattern.pattern
                }
            }

            if (filter.name === 'preço' || filter.name === 'tamanho-do-imovel') {
                const [min, max] = filter.value.split('_')
                const suffix = patterns['any']?.suffix || ''
                if (min === '0' && max) {
                    filtersUrl += `/ate-${max}-${suffix}`
                }
                if (min !== '0') {
                    if (max) {
                        filtersUrl += `/de-${min}-a-${max}-${suffix}`
                    } else {
                        filtersUrl += `/a-partir-de-${min}-${suffix}`
                    }
                }
            }
        })
        return filtersUrl
    }

    const buildSortUrl = (sort: Sort | undefined) => {
        if (!sort) return ''
        let sortUrl = ''
        sortPatterns.forEach((pattern) => {
            if (pattern.name === sort?.name && pattern.value === sort?.value) {
                sortUrl = pattern.pattern.toString()
            }
        })
        return sortUrl
    }

    let returnPath = buildBaseUrl(state.action || 'alugar', params?.slugs || [])
    const { regionUrl, streetUrl } = buildRegionUrl(state.regions || [], state.logradouro)

    if (regionUrl) returnPath += `/${regionUrl}`
    if (streetUrl) returnPath += `/${streetUrl}`

    const filtersUrl = buildFiltersUrl(state.filters || [])
    returnPath += filtersUrl

    const sortUrl = buildSortUrl(state.sort)
    returnPath += sortUrl

    if (state.page && state.page > 1) {
        returnPath += `?page=${state.page}`
    }

    if (returnPath.endsWith('/')) {
        returnPath = returnPath.slice(0, -1)
    }

    return returnPath.replace(/\/\//g, '/')
}

export const getFiltersSortAndAddressSegments = async (
    slug: string[],
    filterPatterns: FilterPattern[]
) => {
    const filters: Filter[] = []
    let sort = null
    const addressSegments = [...slug]
    if (!filterPatterns) {
        filterPatterns = await getDynamicFilterPatterns()
    }

    const parsePriceRange = (segment: string): string => {
        const fromTo = /de-(\d+)-a-(\d+)/
        const upTo = /ate-(\d+)/
        const startingAt = /a-partir-de-(\d+)/

        if (fromTo.test(segment)) {
            const [, x1, x2] = segment.match(fromTo)!
            return `${x1}_${x2}`
        } else if (upTo.test(segment)) {
            const [, x] = segment.match(upTo)!
            return `0_${x}`
        } else if (startingAt.test(segment)) {
            const [, x] = segment.match(startingAt)!
            return `${x}_`
        }
        return ''
    }

    for (let i = slug.length - 1; i >= 0; i--) {
        const segment = slug[i]
        let isCorrectSlug = false

        for (const pattern of filterPatterns) {
            if (new RegExp(`^${pattern.pattern.source}$`).test(segment)) {
                let filterValue = ''

                if (pattern.name === 'preço' || pattern.name === 'tamanho-do-imovel') {
                    filterValue = parsePriceRange(segment)
                } else {
                    filterValue = pattern.value
                }

                const existingFilter = filters.find((f) => f.name === pattern.name)
                if (existingFilter && existingFilter.type === 'string') {
                    existingFilter.value += `, ${filterValue}`
                } else {
                    filters.push({
                        name: pattern.name,
                        value: filterValue,
                        type: FiltersRegistry[pattern.name],
                        is_null: false,
                        operator: FiltersRegistry[pattern.name] === 'string' ? 'in' : '',
                    })
                }

                addressSegments.pop()
                isCorrectSlug = true
                break
            }
        }

        // Check if the segment matches any of the sort patterns
        if (!isCorrectSlug) {
            for (const pattern of sortPatterns) {
                if (pattern.pattern.test(segment)) {
                    sort = {
                        name: pattern.name,
                        value: pattern.value,
                        sort: pattern.sort,
                    } as Sort

                    addressSegments.pop()
                    isCorrectSlug = true
                    break
                }
            }
        }

        // Stop if the segment is not a filter or sort
        if (!isCorrectSlug) {
            break
        }
    }

    return { filters, sort, addressSegments }
}

const searchEntity = {
    INITIAL_STATE,
    setRegions,
    setSort,
    setLogradouro,
    addFilters,
    addSingleFilter,
    removeFilter,
    clearFilters,
    buildUrl,
    buildFiltersFromQuery,
}

export default searchEntity
